<template>
  <div class="wrapper">
    <br><br><br><br>
    <div class="section">
      <div class="container">
        <div class="minting-bi-wrap">
          <img class="minting-bi" :src="roadmap_title">
        </div>
        
        <div class="benefits-wrap benefits-custom-0">
          <h4 class="benefits-sub-title">
            {{language[road_map[select_index].sub_title]}}
          </h4>
          <h3 class="benefits-title">
            {{language[road_map[select_index].title]}}
          </h3>
          
          <ul class="todo-lists">
            <li class="items" v-for="(item, index) in road_map[select_index].text" :key="`todo_${index}`">
              {{language[item]}}
            </li>
          </ul>
        </div>
        <div class="steps-wrap md-layout">
          <template v-for="(item, index) in road_map">
            <div
              class="md-layout-item md-size-20 md-small-size-33"
              :key="index">
              <div class="plan">
                <a class="coloured-img"
                   :class="`plan-${index} roadmap-custom-${index}`"
                   href="javascript:void(0)"
                   style="display: flex; align-items: center; vertical-align: middle;"
                   @mouseover="mouseOverRoadMapModal(index)"
                   @click="toggleRoadmapModal(index)">
                   <div :class="`object-fit-box cont_${index}`">
                    <img :id="`selectedImg-${index}`" :src="item.status? item.imgOn : item.imgOff" :alt="item.title"/>
                    <img :src="item.status? item.imgOn : item.imgOn" :alt="item.title"/>
                  </div>
                </a>
              </div>
            </div>
          </template>
        </div>
        <br><br><br><br><br><br><br><br>
      </div>
    </div>
    <video class="mainVideo desktop" autoplay loop="" muted="" poster="" playsinline>
      <source :src="back" type="video/mp4">
    </video>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import AOS from "aos";
  import "aos/dist/aos.css";
  import Mixins from "@/plugins/basicMixins";
  import Device from '../../assets/sdk/device';

  export default {
    bodyClass: "ac-roadmap",
    components: {},
    mixins: [Mixins.HeaderImage],
    created() {
      AOS.init();
    },
    computed: {
      ...mapGetters([
        "base",
        'language',
      ]),
    },
    methods: {
      toggleRoadmapModal(index) {
        
        this.road_map.some((element, idx) => {
          if(index != idx) {
            $("#selectedImg-" + idx).attr("src",this.road_map[idx].imgOff)
            // this.road_map[idx].status = true;
            this.modalRoadmap = true;
            this.select_index = index;
          }else{
            element.status = false;
          }
        });
        if(!Device.isMobile()){
          $(".benefits-custom-0").css('background-image','url(' + this.road_map[index].bg +')');
        }
        $("#selectedImg-" + index).attr("src",this.road_map[index].imgOn)
       
      },
      mouseOverRoadMapModal(index) {
        
      }
    },
    updated: function updated() {
      $("#selectedImg-" + this.select_index).attr("src",this.road_map[this.select_index].imgOn)

    },
    mounted:  function mounted() {
      if(!this.modalRoadmap){
        this.road_map[0].status = true;
      }
    },
    data() {
      return {
        image_head_bg: require("@/assets/img/bg/00_pattern1.png"),
        roadmap_title: require("@/assets/img/pages/roadmap/roadmap.png"),
        ticket: require("@/assets/img/pages/roadmap/ticket_width.png"),
        back: require("@/assets/img/pages/index/03_schedule_BG.mp4"),
        modalRoadmap: false,
        select_index: 0,
        road_map: [
          {
            status: false,
            imgOn: require("@/assets/img/pages/roadmap/image_01_ani_on.gif"),
            imgOff: require("@/assets/img/pages/roadmap/image_01_ani_off.png"),
            bg: require("@/assets/img/pages/roadmap/textbox_01_ani.png"),
            title: 'roadmap_title_01',
            sub_title: 'roadmap_title_01_1',
            text: [
              'roadmap_item_01_01',
              'roadmap_item_01_02',
              'roadmap_item_01_03',
              'roadmap_item_01_04',
            ]
          },
          {
            status: false,
            imgOn: require("@/assets/img/pages/roadmap/image_02_pinky_on.gif"),
            imgOff: require("@/assets/img/pages/roadmap/image_02_pinky_off.png"),
            bg: require("@/assets/img/pages/roadmap/textbox_02_pinky.png"),
            title: 'roadmap_title_02',
            sub_title: 'roadmap_title_02_1',
            text: [
              'roadmap_item_02_01',
              'roadmap_item_02_02',
              'roadmap_item_02_03',
            ]
          },
          {
            status: false,
            imgOn: require("@/assets/img/pages/roadmap/image_03_blue_on.gif"),
            imgOff: require("@/assets/img/pages/roadmap/image_03_blue_off.png"),
            bg: require("@/assets/img/pages/roadmap/textbox_03_blue.png"),
            title: 'roadmap_title_03',
            sub_title: 'roadmap_title_03_1',
            text: [
              'roadmap_item_03_01',
              'roadmap_item_03_02',
              'roadmap_item_03_03',
            ]
          },
          {
            status: false,
            imgOn: require("@/assets/img/pages/roadmap/image_04_mongyi_on.gif"),
            imgOff: require("@/assets/img/pages/roadmap/image_04_mongyi_off.png"),
            bg: require("@/assets/img/pages/roadmap/textbox_04_mongyi.png"),
            title: 'roadmap_title_04',
            sub_title: 'roadmap_title_04_1',
            text: [
              'roadmap_item_04_01',
            ]
          },
          {
            status: false,
            imgOn: require("@/assets/img/pages/roadmap/image_05_lucy_on.gif"),
            imgOff: require("@/assets/img/pages/roadmap/image_05_lucy_off.png"),
            bg: require("@/assets/img/pages/roadmap/textbox_05_lucy.png"),
            title: 'roadmap_title_05',
            sub_title: 'roadmap_title_05_1',
            text: [
              'roadmap_item_05_01',
              'roadmap_item_05_02',
              'roadmap_item_05_03',
            ]
          },
        ],
      };
    }
  };
</script>

<style lang="scss">


  $ac-section-bg: #ff6371 !default;

  .ac-roadmap {
    color: white;

    .wrapper {
      text-align: center;

      .mainVideo {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        z-index: 0;
      }
      .section {
        position: relative;
        z-index: 1;
      }
      .benefits-wrap {
        background: #0c0a26;
        border-radius: 10px;
        box-shadow: 0 4px 25px 0 rgb(104 37 204 / 80%);
        max-width: 500px;
        margin: 30px auto 30px;
        height: 300px;
        width: 340px;

        .benefits-title {
          font-size: 24px;
          font-weight: 800;
          margin: 40px 0 0;
          color: #9d5bff !important;
        }

        .benefits-sub-title {
          // background-color: #9d5bff;
          color: #0a0c26 !important;
          border-radius: 10rem;
          // font-size: 15px;
          font-weight: 800;
          display: inline-block;
          margin-top: 10px;
          margin-bottom: 5px;
          padding: 2px 10px 1px;
          background-color: none !important;
          /*vus css 우선적용 떄문에 작동안함 수정필요*/
          color: #ffffff !important;
          font-size: 24px !important;
          font-weight: 800 !important;
          line-height: 12px !important;
        }

        ul {
          list-style-position: inside;
          text-align: left;
          margin: 30px;
          padding: 0;
          li {
            padding: 0;
            color: #cecece;
            max-width: 294px;
            margin: 0 auto;
          }
        }
      }

      .steps-wrap {
        .md-layout-item {
          height: 210px;
          padding-right: 0;
          padding-left: 0;

          &.md-small-size-50 {
            .plan {
              width: 70%;
              height: 70%;
              position: relative;
            }
          }

          .plan {
            width: 100%;
            height: 100%;
            position: relative;
          }

          .coloured-img {
            position: absolute;
            display: block;
            width: 100%;
          }

          .plan-0 {
            left: 0;
            top: 0;
            -webkit-animation-name: Floatingy2;
            -moz-animation-name: Floatingy2;
            -webkit-animation-duration: 8s;
            -moz-animation-duration: 8s;
          }
          .plan-1 {
            left: 0;
            top: 50px;
            -webkit-animation-name: Floatingy2;
            -moz-animation-name: Floatingy2;
            -webkit-animation-duration: 4s;
            -moz-animation-duration: 4s;
          }
          .plan-2 {
            left: 0;
            top: 30px;
            -webkit-animation-name: Floatingy;
            -moz-animation-name: Floatingy;
            -webkit-animation-duration: 5s;
            -moz-animation-duration: 5s;
          }
          .plan-3 {
            left: 0;
            top: 0;
            -webkit-animation-name: Floatingy1;
            -moz-animation-name: Floatingy1;
            -webkit-animation-duration: 10s;
            -moz-animation-duration: 10s;
          }
          .plan-4 {
            left: 0;
            top: 30px;
            -webkit-animation-name: Floatingy;
            -moz-animation-name: Floatingy;
            -webkit-animation-duration: 6s;
            -moz-animation-duration: 6s;
          }
          .plan-5 {
            left: 0;
            top: 50px;
            -webkit-animation-name: Floatingy;
            -moz-animation-name: Floatingy;
            -webkit-animation-duration: 10s;
            -moz-animation-duration: 10s;
          }
        }
      }

      .coloured-img {
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-iteration-count: infinite;
        -moz-animation-timing-function: ease-in-out;

        /*float: left;*/
      }




      .coloured-img{

      }


      // animated images

      @-webkit-keyframes Floatingy{
        from {-webkit-transform:translate(0, 0px);}
        65% {-webkit-transform:translate(0, 50px);}
        to {-webkit-transform: translate(0, -0px);}
      }

      @-webkit-keyframes Floatingy1{
        from {-webkit-transform:translate(0, 0px);}
        65% {-webkit-transform:translate(0, 70px);}
        to {-webkit-transform: translate(0, -0px);}
      }

      @-webkit-keyframes Floatingy2{
        from {-webkit-transform:translate(0, 0px);}
        65% {-webkit-transform:translate(0, 30px);}
        to {-webkit-transform: translate(0, -0px);}
      }
    }
    //.modal-roadmap {
    //  &.show {
    //    padding-top: 230px;
    //
    //    /*background-color: rgba(0, 0, 0, .5);*/
    //  }
    //  .modal-container {
    //    background: #0c0a26;
    //    border-radius: 10px;
    //    box-shadow: 0 4px 25px 0 rgb(104 37 204 / 80%);
    //    max-width: 500px;
    //    margin: 30px auto 30px;
    //
    //
    //    .modal-header {
    //      display: none;
    //    }
    //
    //    .modal-body {
    //      padding: 0;
    //      margin:0 auto;
    //
    //      .title {
    //        background-color: #9d5bff;
    //        color: #0a0c26! important;
    //        border-radius: 10rem;
    //        font-size: 17px;
    //        font-weight: 800;
    //        display: inline-block;
    //        margin-top: 30px;
    //        margin-bottom: 5px;
    //        padding: 2px 10px 1px;
    //      }
    //
    //      .todo-lists {
    //        list-style-position: inside;
    //        text-align: left;
    //        margin: 30px;
    //        padding: 0;
    //
    //        .items {
    //          padding: 0;
    //          color: #cecece;
    //        }
    //      }
    //
    //    }
    //
    //    .modal-footer {
    //      .md-button {
    //        background-color: #28e5cc !important;
    //      }
    //    }
    //  }
    //}
  }

  .ac-roadmap .wrapper .benefits-wrap {
      max-width: 500px !important;
      height: 500px !important;
      width: 500px !important;
      background-position: center;
      background-repeat: no-repeat;
      margin: 0 auto !important;
  }

  .ac-roadmap .wrapper .benefits-wrap.benefits-custom-0 {
      background-image: url("../../assets/img/pages/roadmap/textbox_01_ani.png");
      background-size: 500px;
  }
  
  .ac-roadmap .wrapper .benefits-wrap.benefits-custom-0 .todo-lists {
      width: 300px;
      text-align: center;
  }

  .roadmap_img {
      width: 210px;
      height: 210px;
      background-position: center;
      background-repeat: no-repeat;
      /*background-size: cover;*/
  }
 
  // .roadmap_img.cont_0 {
  //     background-image: url("../../assets/img/pages/roadmap/image_02_pinky_off.png");
  // }

  // .roadmap_img.cont_0:hover {
  //     background-image: url("../../assets/img/pages/roadmap/image_02_pinky_off.png");
  //     transition: .5s;
  //     filter: drop-shadow(0px 4px 10px #ffcc21);
  //     overflow: visible;
  //     /*background-size: 246px;*/
  // }

  // .roadmap_img.cont_1 {
  //     background-image: url("../../assets/img/pages/roadmap/image_02_pinky_off.png");
  // }

  // .roadmap_img.cont_1:hover {
  //     background-image: url("../../assets/img/pages/roadmap/image_04_mongyi_on.gif");
  //     transition: .5s;
  //     filter: drop-shadow(0px 5px 12px #F52FA8);
  // }

  // .roadmap_img.cont_2 {
  //     background-image: url("../../assets/img/pages/roadmap/image_03_blue_off.png");
  // }

  // .roadmap_img.cont_2:hover {
  //     background-image: url("../../assets/img/pages/roadmap/image_03_blue_on.gif");
  //     transition: .5s;
  //     filter: drop-shadow(0px 5px 15px #f5b060);
  // }

  // .roadmap_img.cont_3 {
  //     background-image: url("../../assets/img/pages/roadmap/image_04_mongyi_off.png");
  // }

  // .roadmap_img.cont_3:hover {
  //     background-image: url("../../assets/img/pages/roadmap/image_04_mongyi_on.gif");
  //     transition: .5s;
  //     filter: drop-shadow(0px 4px 15px rgb(141, 222, 79));
  // }

  // .roadmap_img.cont_4 {
  //     background-image: url("../../assets/img/pages/roadmap/image_05_lucy_off.png");
  // }

  // .roadmap_img.cont_4:hover {
  //     background-image: url("../../assets/img/pages/roadmap/image_05_lucy_on.gif");
  //     transition: .5s;
  //     filter: drop-shadow(0px 4px 10px rgba(87, 195, 255, 0.8));
  // }

  .ac-roadmap .wrapper .benefits-wrap {
      max-width: 500px !important;
      height: 500px !important;
      width: 500px !important;
      background-position: center;
      background-repeat: no-repeat;
      margin: 0 auto !important;
      padding-left: 40px !important;
  }
 
  .ac-roadmap .wrapper .benefits-wrap {
      background-color: none !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
  }
 
  .ac-roadmap .wrapper .benefits-wrap.benefits-custom-0 {
      background: none;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      background-image: url("../../assets/img/pages/roadmap/textbox_01_ani.png");
      background-size: 500px;
  }

  .ac-roadmap .wrapper .benefits-wrap.benefits-custom-0 .todo-lists {
      width: 300px;
      text-align: center;
  }

  .roadmap_img {
      width: 210px;
      height: 210px;
  }

  .cont_0 img{
      transition: .5s;
  }

  .cont_1:hover img{
      transition: .5s;
  }

  .cont_2:hover img{
      transition: .5s;
  }

  .cont_3:hover img{
      transition: .5s;
  }

  .cont_4:hover img{
      transition: .5s;
  }

  .object-fit-box {
      display: inline-flex;
      overflow: visible;
      width: 210px;
      height: 210px;
  }

  .object-fit-box > img {
      object-fit: cover;
      width: inherit;
      height: inherit;
      object-position: center;
  }

  .object-fit-box > img:nth-of-type(2) {
      display: none;
  }

  .object-fit-box:hover > img:nth-of-type(1) {
      display: none;
  }

  .object-fit-box:hover > img:nth-of-type(2) {
      display: block;
  }  
  
  .ac-roadmap .wrapper .benefits-wrap.benefits-custom-0 .todo-lists {
      width: auto !important;
  }
  
  .ac-roadmap .wrapper .benefits-wrap .benefits-title {margin-top: 180px !important;}


  .ac-roadmap .wrapper .benefits-wrap {
    max-width: 500px !important;
    height: 500px !important;
    width: 500px !important;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto !important;
    padding-left: 40px !important;
}

.ac-roadmap .wrapper .benefits-wrap.benefits-custom-0 {
    background: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-image: url("../../assets/img/pages/roadmap/textbox_01_ani.png");
    background-size: 500px;
    transform: translateX(-20px);
}

.ac-roadmap .wrapper .benefits-wrap.benefits-custom-0 .todo-lists {
    width: 300px;
    text-align: center;
}

/*▒▒▒▒로드맵 이미지 커스텀▒▒▒▒*/

.roadmap_img {
    width: 210px;
    height: 210px;
}


.cont_0 img {
    transition: .5s;
    filter: drop-shadow(0px 4px 8px #ffcc21) !important;
}

.cont_1:hover img {
    transition: .5s;
    filter: drop-shadow(0px 5px 10px #F52FA8) !important;
}

.cont_2:hover img {
    transition: .5s;
    filter: drop-shadow(0px 5px 15px #f5b060) !important;
}

.cont_3:hover img {
    transition: .5s;
    filter: drop-shadow(0px 4px 15px rgb(141, 222, 79)) !important;
}

.cont_4:hover img {
    transition: .5s;
    filter: drop-shadow(0px 4px 10px rgba(87, 195, 255, 0.8)) !important;
}


.object-fit-box {
    display: inline-flex;
    overflow: visible;
    width: auto;
    height: 210px;
}

.object-fit-box>img {
    object-fit: cover;
    width: inherit;
    height: inherit;
    object-position: center;
}

.object-fit-box>img:nth-of-type(2) {
    display: none;
}

.object-fit-box:hover>img:nth-of-type(1) {
    display: none;
}

.object-fit-box:hover>img:nth-of-type(2) {
    display: block;
}

.ac-roadmap .wrapper .benefits-wrap.benefits-custom-0 .todo-lists {
    width: auto !important;
    margin-top: 12px;
    font-size: 15px;
}


.ac-roadmap .wrapper .benefits-wrap .benefits-title {
    font-size: 32px;
    font-weight: 900;
    margin: 40px 0 0;
    color: #9d5bff !important;
    width: 250px;
    line-height: 32px;
    margin: 0 auto !important;
}

.ac-roadmap .wrapper .benefits-wrap .benefits-sub-title {
  margin-top: 166px !important;
  margin-bottom: 16px !important;
  background-color: #FFFFFF !important;
  color: #000000 !important;
  font-size: 16px !important;
  font-weight: 800 !important;
  line-height: 18px !important;
  padding: 2px 10px 1px;
}


.ac-roadmap .wrapper .benefits-wrap .benefits-title {
    width: 160px;
    font-size: 32px !important;
    font-weight: 900;
    color: #ffffff !important;
}
 
@media (max-width: 540px) {

    .object-fit-box {
        width: auto;
        height: 150px;
    }

    .steps-wrap .md-layout {
        justify-content: center;
    }

    .ac-roadmap .wrapper .benefits-wrap {
        background: #0c0a26 !important;
        border-radius: 10px !important;
        -webkit-box-shadow: 0 4px 25px 0 rgba(104, 37, 204, .8) !important;
        box-shadow: 0 4px 25px 0 rgba(104, 37, 204, .8) !important;
        max-width: 500px !important;
        margin: 30px auto 30px !important;
        height: 300px !important;
        width: 340px !important;
        padding-left: 0 !important
    }

    .ac-roadmap .wrapper .benefits-wrap.benefits-custom-0 {
        background-image: none;
        background-size: none;
        transform: none;
    }

    .ac-roadmap .wrapper .benefits-wrap.benefits-custom-0 .todo-lists {
        width: 100%;
        text-align: left;
    }

    .ac-roadmap .wrapper .benefits-wrap .benefits-sub-title {
        margin-top: 40px !important;
        margin-bottom: 16px !important;
    }

    .steps-wrap.md-layout {
        justify-content: center;
    }
    
    .cont_0 img {
        transition: .5s;
        filter: drop-shadow(0px 4px 8px #ffcc21);
    }
    

    .cont_1:hover img {
        transition: .5s;
        filter: drop-shadow(0px 5px 10px #F52FA8);
    }

    .cont_2:hover img {
        transition: .5s;
        filter: drop-shadow(0px 5px 11px #f5b060);
    }

    .cont_3:hover img {
        transition: .5s;
        filter: drop-shadow(0px 4px 11px rgb(141, 222, 79));
    }

    .cont_4:hover img {
        transition: .5s;
        filter: drop-shadow(0px 4px 6px rgba(87, 195, 255, 0.8));
    }
    
    .ac-roadmap .wrapper .steps-wrap .md-layout-item {
        height: 170px !important;
    }
 
}

@media (max-width: 960px) {
    .ac-roadmap .wrapper .section .container {
        margin-bottom: -120px;
    }
    .ac-roadmap .minting-bi-wrap {
        margin-bottom: 48px;
        margin-top: 24px;
    }
}
</style>
